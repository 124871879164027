import React from "react";
import styled from "styled-components";
import themes from "../../themes";
import { devices } from "../../constants";

const ContainerStyle = styled.div`
  width: 100%;
  //width 940px + padding 20-20
  max-width: 1000px;
  height: ${(props) => props.height};
  margin: ${(props) => props.margin || "25px auto"};
  padding: 0 10px;

  //ipad size
  @media only screen and (${devices.tablet}) {
    //width 648px + padding 20-20
    max-width: 850px;
  }
  //iphone size
  @media only screen and (${devices.mobile}) {
    //width 335px + padding 20-20
    max-width: 420px;
  }
`;

const ContentContainerStyle = styled.div`
  position: relative;
  background: ${(props) => themes.colors[props.bgcolor] || themes.colors.white};
  width: 100%;
  min-height: ${(props) => props.minHeight || "300px"};
  margin: ${(props) => props.margin || "10px 0 0 0"};
  border-radius: 12px;
  padding: ${(props) => props.padding || "48px 160px"};
  cursor: ${(props) => props.cursor};
  box-shadow: 0px 4px 16px 0px rgba(0, 10, 27, 0.1);

  //ipad size
  @media only screen and (${devices.tablet}) {
    padding: ${(props) => props.paddingTablet || props.padding || "29px 35px"};
  }
  //iphone size

  @media only screen and (max-width: 510px) {
    padding: ${(props) => props.paddingMobile || props.padding || "24px 20px"};
  }
`;

export const ContentContainer = (props) => {
  const { children, className } = props;

  return (
    <ContentContainerStyle {...props} className={className}>
      {children}
    </ContentContainerStyle>
  );
};

export const Container = (props) => {
  const { children, className } = props;

  return (
    <ContainerStyle className={className} {...props}>
      {children}
    </ContainerStyle>
  );
};
