import React, { useState, useRef, useEffect } from "react";
import Context from "../../Context";
import {
  Container,
  Typo,
  Flex,
  ContentContainer,
  Breadcrumb,
} from "../../components";
import { useToast } from "../../components/ui";
import constant from "../../constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { shortDate, shortTime } from "../../util";
import { Spinner } from "reactstrap";
import { FaCheckCircle } from "react-icons/fa";
import { FaRegCopy } from "react-icons/fa6";

export const Affilate = () => {
  const { profileState, callApi, configs } = Context();
  const { toast } = useToast();
  const [state, setState] = useState(false);
  useEffect(() => {
    getUserReferrer();
  }, []);

  const getUserReferrer = async () => {
    const { totalOrder, totalUser, orders, users } = await callApi(
      "referralPoints-getUserReferrer"
    );
    const history = [...orders, ...users];
    const roundDateIncome = orders.reduce((acc, order) => {
      acc += order.size;
      return acc;
    }, 0);

    setState({
      totalOrder,
      totalUser,
      currentOrderSize: orders.length,
      roundDateIncome,
      history: history,
    });
  };

  return (
    <Container>
      <Breadcrumb
        data={[{ path: "/", name: "หน้าแรก" }, { name: "รายได้ชวนเพื่อน" }]}
      />
      <p
        className="my-9 font-semibold text-navy text-center text-xl"
        color="navy"
        // textAlign="center"
      >
        รายได้ชวนเพื่อน
      </p>
      {/* totalincome */}
      <div className="flex flex-col shadow-sm justify-between w-full bg-white rounded-2xl p-4 gap-4">
        <p className="text-xl font-medium">รายได้สะสม </p>
        <p className="text-right text-3xl font-semibold">
          {new Intl.NumberFormat().format(profileState.buyCash || 0)} บาท
        </p>

        <button
          disabled={(profileState.buyCash || 0) < 100}
          className="text-gray-50 font-semibold p-2 bg-gradient-to-r from-[#002C7B] to-[#2065C4] rounded-xl hover:bg-none hover:text-navy border-2 hover:border-navy duration-150 disabled:bg-none disabled:opacity-60 disabled:bg-slate-400 disabled:border-slate-400 disabled:text-white disabled:cursor-not-allowed"

          // onClick={() => alert("asdfasdf")}
        >
          ถอนเงิน
        </button>

        <div>
          <p className="text-sm font-semibold mb-1">วิธีรับรายได้</p>
          <p className="text-xs list-disc">
            1. คัดลอกลิงก์และแชร์ให้เพื่อนไปยังช่องทางต่าง ๆ เช่น Line Facebook
          </p>
          <p className="text-xs mb-3">
            2. ชวนเพื่อนมาสมัครสมาชิกใหม่ผ่านลิงก์ของลูกค้า
          </p>
          <p className="text-sm font-semibold mb-1">
            เงื่อนไขการรับรายได้ชวนเพื่อน
          </p>
          <p className="text-xs ">
            1. เพื่อนของลูกค้าต้องเป็น " สมาชิกใหม่ " เท่านั้น
          </p>
          <p className="text-xs">
            2. เพื่อนของลูกค้าต้องสั่งซื้อลอตเตอรี่ขั้นต่ำ 1 ใบภายในงวดนั้น
          </p>

          <p className="text-xs">
            3. ลูกค้าต้องยอดสะสมขั้นต่ำ 100 บาทขึ้นไปจึงจะสามารถถอนได้
          </p>

          {/* <p className="text-xs font-medium">
            - จะได้รับค่าตอบเเทนใบละ 2 บาท
            - เรทรายได้งวด{" "}
            {
              <span className="text-xs font-bold ">
                {new Date(configs.roundDate).toLocaleDateString("th-TH", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })}
              </span>
            }{" "}
            ใบละ 2 บาท
          </p> */}
          <p className="flex gap-1 text-xs mt-3 font-medium">
            <span className="text-red-500">*</span>{" "}
            บริษัทขอสงวนสิทธิ์ในการเปลี่ยนแปลงค่าแนะนำ และเงื่อนไข
            โดยไม่แจ้งให้ทราบล่วงหน้า
          </p>
        </div>
      </div>
      <div className="flex my-3 flex-col  overflow-hidden justify-between shadow-sm w-full bg-white rounded-2xl p-4">
        <div>
          <p className="text-sm font-medium text-gray-400">ลิ้งชวนเพื่อน</p>
          <p
            className="text-sm xl:text-xl text-center py-2 truncate font-medium break-all"
            id="btnCopyLink"
          >
            {`${constant.webConfigs.customerUrl + "HT/" + profileState.userId}`}
          </p>
        </div>
        <button
          className="bg-[#FFCD00] flex items-center justify-center gap-2 px-4 py-2 mt-2 font-semibold rounded-xl duration-150 border-2 border-[#FFCD00] hover:bg-transparent hover:text-[#FFCD00]"
          onClick={() => {
            const copyText = document.getElementById("btnCopyLink").innerHTML;
            navigator.clipboard.writeText(copyText);
            // navigator.clipboard.writeText(link[1].value);
            toast({
              className: "bg-[rgb(59,176,101)]",
              title: (
                <div className="flex gap-2 items-center">
                  <FaCheckCircle /> คัดลอกสำเร็จ
                </div>
              ),
            });
          }}
        >
          <FaRegCopy className="h-4" /> คัดลอก
        </button>
      </div>
      {/* sumary */}
      <div className="flex flex-col w-full md:flex-row gap-3 my-3">
        <div className="flex flex-col shadow-sm hover:scale-105 duration-150 justify-between text-white bg-[rgb(59,176,101)] rounded-2xl p-4 w-full md:w-1/2 gap-4">
          <p className="text-xl font-medium">สมัครสมาชิกสำเร็จ</p>

          {state ? (
            <p className="text-center text-3xl font-semibold">
              {(state.totalUser || 0).toLocaleString()}
            </p>
          ) : (
            <Spinner className="text-center self-center text-[#FFCD00]" />
          )}

          <p className="text-right text-lg font-medium">ลิงก์</p>
        </div>
        {/* <div className="flex flex-col shadow-sm hover:scale-105 duration-150 justify-between text-white bg-[#37beb0] rounded-2xl p-4  w-full md:w-1/2 gap-4">
          <p className="text-xl font-medium">ออเดอร์ทั้งหมด</p>

          {state ? (
            <p className="text-center text-3xl font-semibold">
              {(state.totalOrder || 0).toLocaleString()}
            </p>
          ) : (
            <Spinner className="self-center text-[#FFCD00]" />
          )}

          <p className="text-right text-lg font-medium">ออเดอร์</p>
        </div> */}
        {/* <div className="flex flex-col shadow-sm hover:scale-105 duration-150 justify-between bg-white rounded-2xl p-4  w-full md:w-1/2 gap-4">
          <p className="text-xl font-medium">ออเดอร์งวดปัจจุบัน</p>

          {state ? (
            <p className="text-center text-3xl font-semibold">
              {(state.currentOrderSize || 0).toLocaleString()}
            </p>
          ) : (
            <Spinner className="self-center text-[#FFCD00]" />
          )}

          <p className="text-right text-lg font-medium">ออเดอร์</p>
        </div> */}
        <div className="flex flex-col hover:scale-105 shadow-sm duration-150 justify-between bg-[#FFCD00] rounded-2xl p-4  w-full md:w-1/2 gap-4">
          <p className="text-xl font-medium">รายได้งวดปัจจุบัน</p>

          {state ? (
            <p className="text-center text-3xl font-semibold">
              {(state.roundDateIncome * 2).toLocaleString()}
            </p>
          ) : (
            <Spinner className="self-center text-[#FFCD00]" />
          )}

          <p className="text-right text-lg font-medium">บาท</p>
        </div>
      </div>

      {/* transaction history */}
      {/* <div className="flex flex-col mt-3 shadow-sm w-full bg-white rounded-2xl p-4 gap-4">
        <p className="text-2xl font-medium">
          ประวัติรายได้งวดปัจจุบัน{" "}
          <span className=" font-medium text-yellow-500">| </span>
          <span className="text-sm font-medium text-gray-400">
            {new Date(configs.roundDate).toLocaleDateString("th-TH", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}
          </span>
        </p>
      </div>
      <div className="mb-24">
        {state ? (
          state.history.length > 0 ? (
            state.history
              .sort((a, b) => b.createdAt - a.createdAt)
              .map((order, index) => {
                return (
                  <div
                    key={index}
                    className="flex justify-between items-end shadow-sm mx-auto md:w-full xl:w-11/12 bg-white my-3 rounded-2xl p-4 gap-4 duration-150 hover:scale-105"
                  >
                    <div className="flex flex-col">
                      <p className="text-sm font-medium">เลขที่อ้างอิง</p>
                      <p className="text-2xl font-bold">
                        {order.shortId || order.userId}
                      </p>
                      <p className="text-sm font-medium text-gray-400">{`${
                        shortDate(order.createdAt) +
                        " | " +
                        shortTime(order.createdAt)
                      }`}</p>
                    </div>
                    {order.shortId ? (
                      <div className="flex flex-col ">
                        <p className="text-2xl font-bold">{order.size} ใบ</p>
                        <p className="text-xs font-semibold text-green-500 mr-2">
                          +{order.size * 2} บาท
                        </p>
                      </div>
                    ) : (
                      <div className="self-center">
                        <p className="text-md text-green-500 font-medium mr-2">
                          + สมัครสมาชิกสำเร็จ
                        </p>
                      </div>
                    )}
                  </div>
                );
              })
          ) : (
            <div className="flex items-center justify-center w-full h-60">
              <p className="text-gray-400 font-semibold">ไม่พบออเดอร์</p>
            </div>
          )
        ) : (
          <div className="flex items-center justify-center w-full h-60">
            <Spinner />
          </div>
        )}
      </div> */}
    </Container>
  );
};
