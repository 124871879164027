import React, { useEffect, useState } from "react";
import style from "../constants/style";
import styled from "styled-components";
import Context from "../Context";
import { useParams } from "react-router-dom";
import {
  Line,
  ProgressBar,
  TextBody,
  WhiteHolder,
  FlexHolder,
  Flex,
  Input,
  Container,
  ContentContainer,
  Typo,
  Button,
  Grid,
  Div,
  InputBox,
} from "../components";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import provinces from "../constants/province.json";
import { validate, deliverChannels } from "../constants";
import { bankList, roundDateToDate } from "../util";
import * as R from "ramda";
import themes from "../themes";
import { Collapse } from "reactstrap";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../components";
import { FaCheck } from "react-icons/fa6";

export const ReceiverPerson = () => {
  const { channel } = useParams();
  const {
    callApi,
    toast,
    configs,
    sessions,
    showModalConsent,
    hideModal,
    profileState,
    go,
    setCart,
  } = Context();
  const [firstName, setFirstName] = useState(profileState.firstName || "");
  const [lastName, setLastName] = useState(profileState.lastName || "");
  const [phone, setPhone] = useState(profileState.phone || "");
  const [zipcode, setZipcode] = useState(profileState?.address?.zipcode || "");
  const [addr, setAddr] = useState(profileState?.address?.addr || "");
  const [bank, setBank] = useState(profileState?.bank);
  const [province, setProvince] = useState(
    profileState?.address?.province || ""
  );

  const [isShowAddress, setIsShowAddress] = useState(channel === "mail");
  const [isBuyForSomeoneElse, setIsBuyForSomeoneElse] = useState(
    sessionStorage.getItem("sendAsAGift") || false
  );
  const [buyFor, setBuyFor] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    accName: "",
    accNo: "",
    bank: "",
  });
  const [isShowConsent, setIsShowConsent] = useState(false);
  const [isAcceptedConsent, setIsAcceptedConsent] = useState(false);
  const [isShowSubmission, setIsShowSubmission] = useState(false);
  const [isShowOTP, setIsShowOTP] = useState(false);
  const [otpRef, setOtpRef] = useState(profileState?.otp?.ref || "");
  const [digits, setDigits] = useState(["", "", "", "", "", ""]);
  const [expireIn, setExpireIn] = useState(0);
  const [attempt, setAttempt] = useState(1);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isBuyForSomeoneElse, isShowSubmission]);

  const affiliate = sessionStorage.getItem("affiliate");

  const createOrder = async () => {
    if (!firstName || !phone) {
      hideModal();
      return toast("โปรดกรอกข้อมูลให้ครบถ้วน");
    }

    sessionStorage.removeItem("sendAsAGift");

    let isValidAffiliate = profileState.userNo !== affiliate;

    if (!configs.isAffiliateOpen) {
      isValidAffiliate = false;
    }
    let updateBody = {
      firstName,
      lastName: lastName || "-",
      phone,
      bank,
      isGuest: localStorage.guestId ? true : false,
      guestId: localStorage.guestId,
      isBuyForSomeoneElse,
      referrer:
        sessionStorage.referrer === profileState.referrer
          ? profileState.referrer
          : false,
      deliverChannel: "online",
      isAffiliate: !!affiliate ? isValidAffiliate : false,
      affiliateId: !!affiliate ? (isValidAffiliate ? affiliate : false) : false,
    };

    if (isBuyForSomeoneElse) {
      updateBody = {
        ...updateBody,
        buyFor,
      };
    }
    if (isShowAddress) {
      updateBody.deliverChannel = "mail";
      updateBody.address = {
        zipcode,
        province,
        addr,
      };
    }
    if (sessions.influencer) {
      updateBody.influencer = sessions.influencer;
    }

    const result = await callApi("orders-createOrder", updateBody);
    if (result.orderId) {
      sessionStorage.removeItem("referrer");
      setCart({ size: 0, expireIn: 0 });
      go(`/payment/${result.orderId}`);
    } else {
      result.error && toast(result.error);
      go("/");
    }
  };

  const clearCart = async () =>
    callApi("carts-clearCart", { guestId: localStorage.guestId || false }).then(
      () => {
        console.log("receiver");
        setCart({ size: 0, expireIn: 0 });
        hideModal();
        go("/");
      }
    );

  const isValid = () => {
    if (localStorage.guestId) {
      return firstName && phone?.length >= 10;
    }
    if (!isBuyForSomeoneElse && firstName && lastName && phone?.length >= 10) {
      if (channel === "mail") {
        return (
          isValidZipcode(zipcode) && !R.isEmpty(addr) && !R.isEmpty(province)
        );
      }
      return true;
    }

    if (
      isBuyForSomeoneElse &&
      Object.values(buyFor).every((value) => value !== "")
    ) {
      return true;
    }

    return false;
  };

  const isValidZipcode = (input) => {
    return input.length === 5;
  };

  const CountDownText = ({ second = 0 }) => {
    if (second <= 0) return <span>00:00</span>;
    second = parseInt(second / 1000);
    const pattern =
      String(Math.floor(second / 60)).padStart(2, "0") +
      ":" +
      String(second % 60).padStart(2, "0");
    return <span>{pattern}</span>;
  };

  const requestOTP = async () => {
    const tempOtpRef = Math.floor(Math.random() * 60466175)
      .toString(36)
      .toUpperCase()
      .padStart(5, "0");

    const result = await callApi("carts-sendCartOTP", {
      otpRef: tempOtpRef,
      phone,
      guestId: localStorage.guestId,
    });

    if (result.status) {
      setOtpRef(tempOtpRef);
      setExpireIn(result.expireAt - Date.now());
      setDigits(["", "", "", "", "", ""]);
      setIsShowOTP(true);
    }

    if (result.data.attempt) {
      setAttempt(result.data.attempt);
    }
  };

  const submitOTP = async () => {
    const result = await callApi("carts-verifyCartOTP", {
      guestId: localStorage.guestId,
      otp: digits.join(""),
      otpRef,
    });

    if (result.status) {
      setIsShowOTP(false);
      setIsShowSubmission(true);
    }
    if (result.data.attempt) {
      setAttempt(result.data.attempt);
    }
  };

  useEffect(() => {
    const countDown = setInterval(() => {
      if (isShowOTP) {
        setExpireIn((x) => {
          if (x >= 1000) {
            return x - 1000;
          } else {
            setIsShowOTP(false);
            setOtpRef("");
            setDigits([]);
            setExpireIn(0);
            setAttempt(0);
            return;
          }
        });
      } else {
        return () => clearInterval(countDown);
      }
    }, 1000);

    return () => clearInterval(countDown);
  }, [isShowOTP]);

  return (
    <Flex direction="column" width="100%">
      <AlertDialog
        open={isShowOTP}
        onOpenChange={() => {
          setIsShowOTP(false);
          setOtpRef("");
          setDigits([]);
          setExpireIn(0);
          setAttempt(0);
        }}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>ยืนยันการซื้อ</AlertDialogTitle>
            <AlertDialogDescription className="text-md">
              รหัสอ้างอิง:{" "}
              <span className="font-bold text-[#003A9E]">{otpRef}</span>
            </AlertDialogDescription>
            <p className="mb-3">
              หมดอายุภายใน{" "}
              <span className="font-bold text-[#003A9E]">
                {" "}
                <CountDownText second={expireIn} />
              </span>
            </p>

            <InputBox
              digits={digits}
              setDigits={setDigits}
              action={submitOTP}
              gap="15px"
            />
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel className="border-0 hover:text-[#D60B0B]">
              ยกเลิก
            </AlertDialogCancel>

            <button
              className="px-4 py-0 bg-[#003A9E] disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-gray-700 hover:opacity-90 text-white font-semibold rounded-xl"
              disabled={digits.length !== 5}
              onClick={() => submitOTP()}
            >
              ยืนยัน OTP ({attempt}/3)
            </button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <ProgressBar status={2} />
      <Collapse isOpen={isShowSubmission}>
        <Container>
          <ContentContainer padding="34px 26px" margin="0" minHeight="0px">
            <Flex direction="column" gap="2px">
              <Typo.Body2 margin="0 0 28px 0" color="navy">
                ข้อตกลงและเงื่อนไขในการซื้อลอตเตอรี่
              </Typo.Body2>
              <Typo.Body3 color="textBlack" weight="light">
                ข้อตกลงและเงื่อนไขในการซื้อลอตเตอรี่หงษ์ทอง
                คือแพลตฟอร์มจำหน่ายลอตเตอรี่ออนไลน์
              </Typo.Body3>
              <Typo.Body3 color="textBlack" weight="light">
                &#x2022; บริการระบบจัดหาลอตเตอรี่ด้วยระบบ โปรแกรมอัจฉริยะ
              </Typo.Body3>
              <Typo.Body3 color="textBlack" weight="light">
                &#x2022; บริการ แจ้งเตือนรายการสั่งซื้อไปยัง smร หรือ Line
                ของผู้สั่งซื้อ
              </Typo.Body3>
              <Typo.Body3 color="textBlack" weight="light">
                &#x2022; บริการ รับฝากลอตเตอรี่ (พร้อมประกันการ สูญหาย)
              </Typo.Body3>
              <Typo.Body3 color="textBlack" weight="light">
                &#x2022; บริการ รับฝากตรวจผลลอตเตอรี่
              </Typo.Body3>
              <Typo.Body3 color="textBlack" weight="light">
                &#x2022; บริการ แจ้งผลถูกรางวัลไปยัง รmร หรือ Line
                ของผู้สั่งซื้อ
              </Typo.Body3>
              <Typo.Body3 color="textBlack" weight="light">
                &#x2022; บริการ รับขึ้นเงินรางวัล ภายใน 24-48 ชั่วโมง
              </Typo.Body3>
              {!isShowConsent && (
                <Typo.Body3
                  color="navy"
                  weight="bold"
                  cursor="pointer"
                  onClick={() => {
                    setIsShowConsent(true);
                  }}
                >
                  ...more
                </Typo.Body3>
              )}
              <Collapse isOpen={isShowConsent}>
                <Typo.Body3
                  margin="10px 0 0 0"
                  color="textBlack"
                  weight="light"
                >
                  เงื่อนไขการขอรับต้นฉบับลอตเตอรี่
                  หากลอตเตอรี่ของลูกค้าถูกรางวัลสามารถแจ้งความประสงค์เพื่อรับลอตเตอรี่ใบจริงเพื่อ
                  นำไปขึ้นเงินด้วยตนเองได้หรือสามารถให้หงษ์ทองนำลอตเตอรี่ไปขึ้นเงินแทนลูกค้าเพื่อความสะดวกโดยจะไม่มีค่าธรรมเนียมการขึ้น
                  เงินรางวัล และทางหงษ์ทองจะดำเนินการโอนเงินรางวัลให้ลูกค้า
                  ภายใน 24-48 ชั่วโมง หลังจากได้รับ SMS หรือ Line ยืนยันการ
                  ถูกรางวัลจากทางหงษ์ทอง หากลอตเตอรี่ของลูกค้าไม่ถูกรางวัลในงวด
                </Typo.Body3>
                <Typo.Body3
                  margin="10px 0 0 0"
                  color="textBlack"
                  weight="light"
                >
                  หงษ์ทองขอสงวนสิทธิ์ในการขอตรวจสอบอายุผู้ซื้อและฉันตกลงยอมจ่ายค่าปรับให้กับทางหงษ์ทองเป็นเงิน{" "}
                  <span
                    style={{
                      color: themes.colors.red,
                      fontWeight: 600,
                    }}
                  >
                    10,000 (หนึ่งหมื่นบาท ถ้วน)
                  </span>{" "}
                  ต่อการซื้อลอตเตอรี่ 1 ใบ ในกรณีที่หงษ์ทองตรวจพบว่า
                  ฉันซื้อลอตเตอรี่ ในสถานศึกษา และหรืออายุฉันยังไม่ถึง 20
                  ปีบริบูรณ์
                </Typo.Body3>
                <Typo.Body3
                  margin="10px 0 0 0"
                  color="textBlack"
                  weight="light"
                >
                  ฉันตกลงและทราบดีว่าทางหงษ์ทองขอสงวนสิทธิ์ในการไม่โอนเงินรางวัลในกรณีผู้ซื้อถูกรางวัล
                  และจะไม่คืนเงินค่าซื้อลอตเตอรี่ให้กับ ผู้ซื้อลอตเตอรี่
                  ในกรณีที่หงษ์ทองตรวจพบว่า ผู้ซื้อมีอายุต่ำกว่า 20 ปีบริบูรณ์{" "}
                </Typo.Body3>
                {/* <Flex margin="10px 0 0 0" align="flex-start" gap="10px"> */}
                {/* <Input
                  id="consent"
                  type="checkbox"
                  padding="0px"
                  width="30px"
                  height="30px"
                  style={{
                    minWidth: "30px",
                    cursor: "pointer",
                    border: `3px solid ${themes.colors.yellow}`,
                  }}
                  defaultChecked={isAcceptedConsent}
                  value={isAcceptedConsent}
                  onChange={() => {
                    setIsAcceptedConsent((prevState) => !prevState);
                  }}
                /> */}
                {/* <label htmlFor="consent" style={{ cursor: "pointer" }}> */}
                <Typo.Body2 color="navy" textAlign="center" margin="25px 0">
                  กรุณากดปุ่มด้านล่างเพื่อรับรองว่าฉันไม่ได้ซื้อลอตเตอรี่ในสถานศึกษาและปัจจุบันฉันมีอายุครบ
                  20 ปีบริบูรณ์
                </Typo.Body2>
                {/* </label> */}
                {/* </Flex> */}
              </Collapse>
              <Button
                margin="25px 0 0 0"
                disabled={isAcceptedConsent}
                size="18px"
                weight="bold"
                color="green"
                background="lightGreen"
                border="lightGreen"
                hoverBorder="green"
                hoverBg="lightGreen"
                width="100%"
                grow={1}
                gap="10px"
                onClick={() => {
                  setIsAcceptedConsent(true);
                }}
              >
                {isAcceptedConsent && (
                  <FaCheck
                    style={{
                      marginBottom: "5px",
                      color: themes.colors.green,
                    }}
                  />
                )}{" "}
                ข้าพเจ้ารับทราบและรับรอง
              </Button>
            </Flex>
          </ContentContainer>

          <Grid margin="24px 0" columns={2}>
            <Button
              color="navy"
              onClick={() => clearCart()}
              hoverBg="transparent"
              background="transparent"
            >
              ยกเลิกคำสั่งซื้อ
            </Button>
            <Button
              disabled={!isAcceptedConsent}
              width="100%"
              hoverBg="transparent"
              hoverBorder="navy"
              hoverColor="navy"
              background="gradient2"
              onClick={() => createOrder()}
            >
              ยืนยันการซื้อ
            </Button>
          </Grid>
        </Container>
      </Collapse>

      <Collapse isOpen={!isShowSubmission}>
        <Container>
          <ContentContainer
            padding="12px 16px"
            paddingTablet="12px 16px"
            paddingMobile="12px 16px"
            margin="0 0 32px 0"
            bgcolor="newGrReverse"
            minHeight="0"
            width="100%"
          >
            <Typo.H6 textAlign="center">ยืนยันข้อมูลผู้ซื้อ</Typo.H6>
          </ContentContainer>
          {!localStorage.guestId && (
            <Flex
              justify="center"
              width="100%"
              height="68px"
              background="white"
              radius="60px"
              padding="8px"
              margin="0 0 32px 0"
              gap="12px"
            >
              <Button
                grow={1}
                background={!isBuyForSomeoneElse && "yellow"}
                hoverBg={isBuyForSomeoneElse && "white"}
                onClick={() => setIsBuyForSomeoneElse(false)}
                radius="60px"
              >
                <Typo.H6
                  color="navy"
                  weight="bold"
                  hoverColor={isBuyForSomeoneElse && "yellow"}
                >
                  ซื้อให้ตัวเอง
                </Typo.H6>
              </Button>
              <Button
                grow={1}
                background={isBuyForSomeoneElse && "yellow"}
                hoverBg={!isBuyForSomeoneElse && "white"}
                onClick={() =>
                  !localStorage.guestId &&
                  channel !== "mail" &&
                  setIsBuyForSomeoneElse(true)
                }
                radius="60px"
              >
                <Typo.H6
                  color={channel === "mail" ? "gray2" : "navy"}
                  weight="bold"
                  hoverColor={
                    channel === "mail"
                      ? "gray2"
                      : !isBuyForSomeoneElse
                      ? "yellow"
                      : "navy"
                  }
                >
                  {sessionStorage.getItem("sendAsAGift")
                    ? "ส่งของขวัญ"
                    : "ซื้อให้บุคคลอื่น"}
                </Typo.H6>
              </Button>
            </Flex>
          )}
          <ContentContainer
            padding="34px 55px"
            paddingMobile="16px"
            margin="0"
            minHeight="0px"
          >
            {" "}
            {localStorage.guestId ? (
              <Typo.Body2 margin="0 0 28px 0" color="navy">
                ข้อมูลผู้ซื้อใหม่
                <span className="ml-2 text-red-600 font-bold text-2xl">
                  ( ไม่ใช่สมาชิก )
                </span>
              </Typo.Body2>
            ) : (
              <Typo.Body2 margin="0 0 28px 0" color="navy">
                ข้อมูลผู้ซื้อ
              </Typo.Body2>
            )}
            {localStorage.guestId ? (
              <Grid
                columns={2}
                justify="flex-start"
                columnsIpad={1}
                columnsMobile={1}
                gap="20px"
              >
                <Div width="100%">
                  <Typo.Body5 color="gray4">
                    ชื่อ-นามสกุล<span className="text-red-500">*</span>
                  </Typo.Body5>

                  <Input
                    placeholder="ชื่อ-นามสกุล"
                    value={firstName}
                    type="input"
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      setBank((prevState) => ({
                        ...prevState,
                        accName: e.target.value,
                      }));
                    }}
                  />
                </Div>

                <Div width="100%">
                  <Typo.Body5 color="gray4">
                    เบอร์โทรศัพท์<span className="text-red-500">*</span>
                  </Typo.Body5>

                  <Input
                    placeholder="เบอร์โทรศัพท์"
                    value={phone}
                    type="input"
                    onChange={(e) => {
                      if (validate.digitOnly.test(e.target.value)) {
                        setPhone(e.target.value);
                      }
                    }}
                  />
                </Div>

                <Div width="100%">
                  <Typo.Body5 color="gray4">ชื่อบัญชีผู้รับ</Typo.Body5>
                  <Input
                    disabled
                    placeholder="ชื่อผู้รับ"
                    value={bank?.accName}
                    type="input"
                    onChange={(e) => {
                      setBank((prevState) => ({
                        ...prevState,
                        accName: e.target.value,
                      }));
                    }}
                  />
                </Div>

                <Div width="100%">
                  <Typo.Body5 color="gray4">ธนาคารผู้รับ</Typo.Body5>
                  <Input
                    placeholder="ชื่อผู้รับ"
                    value={bank?.bankName}
                    type="select"
                    onChange={(e) => {
                      setBank((prevState) => ({
                        ...prevState,
                        name: e.target.value,
                      }));
                    }}
                  >
                    {Object.values(bankList).map((bank, i) => (
                      <option key={i} value={bank}>
                        {bank}
                      </option>
                    ))}
                  </Input>
                </Div>

                <Div width="100%">
                  <Typo.Body5 color="gray4">เลขบัญชีผู้รับ</Typo.Body5>
                  <Input
                    placeholder="เลขบัญชีผู้รับ"
                    value={bank?.accNo}
                    type="input"
                    onChange={(e) => {
                      setBank((prevState) => ({
                        ...prevState,
                        accNo: e.target.value,
                      }));
                    }}
                  />
                </Div>
              </Grid>
            ) : (
              <Grid
                columns={2}
                justify="flex-start"
                columnsIpad={1}
                columnsMobile={1}
                gap="20px"
              >
                <Div width="100%">
                  <Typo.Body5 color="gray4">
                    ชื่อ<span className="text-red-500">*</span>
                  </Typo.Body5>

                  <Input
                    placeholder="ชื่อ"
                    value={firstName}
                    type="input"
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  />
                </Div>
                <Div width="100%">
                  <Typo.Body5 color="gray4">
                    นามสกุล<span className="text-red-500">*</span>
                  </Typo.Body5>

                  <Input
                    placeholder="นามสกุล"
                    value={lastName}
                    type="input"
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                </Div>
                <Div width="100%">
                  <Typo.Body5 color="gray4">
                    เบอร์โทรศัพท์<span className="text-red-500">*</span>
                  </Typo.Body5>

                  <Input
                    placeholder="เบอร์โทรศัพท์"
                    value={phone}
                    type="input"
                    onChange={(e) => {
                      if (validate.digitOnly.test(e.target.value)) {
                        setPhone(e.target.value);
                      }
                    }}
                  />
                </Div>
              </Grid>
            )}
            {channel === deliverChannels.mail && (
              <>
                <Div width="100%" margin="20px 0">
                  <Typo.Body5 color="gray4">ที่อยู่</Typo.Body5>

                  <Input
                    placeholder="ที่อยู่"
                    value={addr}
                    type="textarea"
                    onChange={(e) => {
                      setAddr(e.target.value);
                    }}
                  />
                </Div>

                <Grid
                  columns={2}
                  justify="flex-start"
                  columnsIpad={1}
                  columnsMobile={1}
                  gap="20px"
                >
                  <Div width="100%">
                    <Typo.Body5 color="gray4">จังหวัด</Typo.Body5>
                    <Input
                      placeholder="จังหวัด"
                      value={province}
                      type="select"
                      onChange={(e) => {
                        setProvince(e.target.value);
                      }}
                    >
                      {!province && <option value="">โปรดเลือก</option>}
                      {provinces.map((province, i) => (
                        <option key={i} value={province}>
                          {province}
                        </option>
                      ))}
                    </Input>
                  </Div>
                  <Div width="100%">
                    <Typo.Body5 color="gray4">เลขไปรษณีย์</Typo.Body5>
                    <Input
                      placeholder="เลขไปรษณีย์"
                      value={zipcode}
                      type="input"
                      onChange={(e) => {
                        setZipcode(e.target.value);
                      }}
                    />
                  </Div>
                </Grid>

                <Typo.Body5 margin="20px 0 0 0" color="red">
                  &#x2022; ค่าจัดส่งใบแรก{" "}
                  {configs.shipping.prices.join(".00 บาท และใบต่อไปใบละ ")}
                  .00 บาท
                </Typo.Body5>
                <Typo.Body5 margin="10px 0 0 0" color="red">
                  &#x2022; ลูกค้าท่านใดที่สั่งซื้อสลากฯ ตั้งแต่วันที่{" "}
                  {roundDateToDate(configs.shipping.start)} -{" "}
                  {roundDateToDate(configs.shipping.end)} ที่มีความประสงค์
                  ต้องการให้บริษัทฯ จัดส่งสลากให้ท่าน
                  ท่านจะมีรอบจัดส่งรอบต่อไปในวันที่{" "}
                  {roundDateToDate(configs.shipping.send)}
                </Typo.Body5>
                <Typo.Body5 margin="10px 0 0 0" color="red">
                  &#x2022; ทางบริษัทฯ ขอสงวนสิทธิ์
                  หากลูกค้าต้องการยกเลิกการจัดส่งใบสลากฯ
                  และท่านจะไม่ได้รับค่าจัดส่งคืนทุกกรณี
                </Typo.Body5>
                <Typo.Body5 margin="10px 0 0 0" color="red">
                  &#x2022; หลังจากจัดส่งแล้ว
                  ทางหงษ์ทองจะให้หมายเลขพัสดุกับท่านภายใน 2 วันทำการของไปรษณีย์
                  หากสูญหายทางบริษัทจะไม่รับผิดชอบใดๆทั้งสิ้น
                  โดยทางลูกค้าต้องติดตามสถานะของพัสดุด้วยตนเอง
                </Typo.Body5>
              </>
            )}
            {isBuyForSomeoneElse && (
              <>
                <Line
                  margin="28px 0"
                  border={`1px solid ${themes.colors.gray2}`}
                />
                <Typo.Body2 margin="0 0 28px 0" color="navy">
                  ข้อมูลผู้รับลอตเตอรี่
                </Typo.Body2>
                <Grid
                  columns={2}
                  justify="flex-start"
                  columnsIpad={1}
                  columnsMobile={1}
                  gap="20px"
                >
                  <Div width="100%">
                    <Typo.Body5 color="gray4">ชื่อผู้รับ</Typo.Body5>

                    <Input
                      placeholder="ชื่อผู้รับ"
                      value={buyFor.firstName}
                      type="input"
                      onChange={(e) => {
                        setBuyFor((prevState) => ({
                          ...prevState,
                          firstName: e.target.value,
                          accName: e.target.value + " " + prevState.lastName,
                        }));
                      }}
                    />
                  </Div>
                  <Div width="100%">
                    <Typo.Body5 color="gray4">นามสกุลผู้รับ</Typo.Body5>

                    <Input
                      placeholder="นามสกุลผู้รับ"
                      value={buyFor.lastName}
                      type="input"
                      onChange={(e) => {
                        setBuyFor((prevState) => ({
                          ...prevState,
                          lastName: e.target.value,
                          accName: prevState.firstName + " " + e.target.value,
                        }));
                      }}
                    />
                  </Div>
                  <Div width="100%">
                    <Typo.Body5 color="gray4">เบอร์โทรศัพท์ผู้รับ</Typo.Body5>

                    <Input
                      placeholder="เบอร์โทรศัพท์ผู้รับ"
                      value={buyFor.phone}
                      type="input"
                      onChange={(e) => {
                        if (validate.digitOnly.test(e.target.value)) {
                          setBuyFor((prevState) => ({
                            ...prevState,
                            phone: e.target.value,
                          }));
                        }
                      }}
                    />
                  </Div>
                  <Div width="100%">
                    <Typo.Body5 color="gray4">ชื่อบัญชีผู้รับ</Typo.Body5>
                    <Input
                      placeholder="ชื่อผู้รับ"
                      value={buyFor.accName}
                      type="input"
                      disabled={true}
                      onChange={(e) => {
                        setBuyFor((prevState) => ({
                          ...prevState,
                          accName: e.target.value,
                        }));
                      }}
                    />
                  </Div>
                  <Div width="100%">
                    <Typo.Body5 color="gray4">ธนาคารผู้รับ</Typo.Body5>
                    <Input
                      placeholder="ชื่อผู้รับ"
                      value={buyFor.bank}
                      type="select"
                      onChange={(e) => {
                        setBuyFor((prevState) => ({
                          ...prevState,
                          bank: e.target.value,
                        }));
                      }}
                    >
                      {Object.values(bankList).map((bank, i) => (
                        <option key={i} value={bank}>
                          {bank}
                        </option>
                      ))}
                    </Input>
                  </Div>
                  <Div width="100%">
                    <Typo.Body5 color="gray4">เลขบัญชีผู้รับ</Typo.Body5>
                    <Input
                      placeholder="เลขบัญชีผู้รับ"
                      value={buyFor.accNo}
                      type="input"
                      onChange={(e) => {
                        setBuyFor((prevState) => ({
                          ...prevState,
                          accNo: e.target.value,
                        }));
                      }}
                    />
                  </Div>
                </Grid>
              </>
            )}
            {localStorage.guestId && (
              <p className="font-medium text-sm mt-4">
                <span className="text-red-500 mr-1">*</span>กรณีถูกรางวัล
                ถ้าหากท่านไม่กรอกข้อมูลบัญชีธนาคาร
                ท่านจะไม่สามารถรับเงินรางวัลได้จนกว่าจะติดต่อให้ข้อมูลบัญชีธนาคารกับทางหงษ์ทอง
              </p>
            )}
          </ContentContainer>

          <Grid margin="24px 0" columns={2}>
            <Button
              color="navy"
              onClick={() => go("/cart")}
              hoverBg="transparent"
              background="transparent"
            >
              <IoIosArrowBack
                style={{
                  color: themes.colors.navy,
                  marginRight: "7px",
                }}
              />
              แก้ไขคำสั่งซื้อ
            </Button>

            <Button
              disabled={!isValid()}
              width="100%"
              hoverBg="transparent"
              hoverBorder="navy"
              hoverColor="navy"
              background="gradient2"
              onClick={() =>
                localStorage.guestId ? requestOTP() : setIsShowSubmission(true)
              }
            >
              {localStorage.guestId ? "ยืนยัน OTP" : "ดำเนินการต่อ"}
              <IoIosArrowForward
                style={{
                  color: isValid() ? themes.colors.yellow : themes.colors.white,
                  marginLeft: "7px",
                }}
              />
            </Button>
          </Grid>
        </Container>
      </Collapse>
      <br />
    </Flex>
  );
};

const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 50%;
  height: 50px;
  background-color: ${themes.colors.gray};
  cursor: pointer;
  font-weight: 500;
  font-size: 1.25em;
  transition-duration: 0.25s;
  border-radius: ${(props) => props.borderRadius};
  &.is-selected {
    background-color: ${themes.colors.secondary};
    border: 0;
    color: ${themes.colors.primary};
    font-size: 1.5em;
  }
`;

const TabHeaderContainer = styled.div`
  display: flex;
  overflow: hidden;
`;

// margin-bottom: 20px;
// const Input = styled.input`
//   ${style.css.smRad}
//   border: 1px solid ${style.colors.goldText};
//   padding: 5px 10px;
//   font-size: 1em !important;
//   width: 100%;
//   -moz-appearance: none; /* older firefox */
//   -webkit-appearance: none; /* safari, chrome, edge and ie mobile */
//   appearance: none; /* rest */
//   @media screen and (min-width: 650px) {
//     font-size: 1.6rem;
//   }
// `;
const Lable = styled.p`
  font-size: 1em !important;
  text-align: left;
  @media screen and (min-width: 650px) {
    font-size: 1.6rem;
  }
  margin: 0;
`;

const Textarea = styled.textarea`
  width: 100%;
  max-width: calc(100%);
  min-width: calc(100% - 20px);
  min-height: 80px;
  font-size: 1.2rem;
  padding: 10px;
  border: 1px solid ${style.colors.goldText};
  border-radius: 0.375rem;
`;
const Select = styled.select`
  text-align: left;
  width: 100%;
  padding: 4px 5px;
  border-radius: 5px;
  border: 1px solid ${themes.colors.primary};
  :focus-visible {
    border: 1px solid gray;
  }
`;
